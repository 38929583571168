import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";

import EngageBanner from "../components/box-engage-banner";
import BannerTickets from "../components/banner-tickets";
import BoxIniziativa from "../components/box-iniziativa";
import BoxSponsor from "../components/box-sponsor";
import BoxNewsletter from "../components/box-newsletter";

const Spline = lazy(() => import("@splinetool/react-spline"));

const DiventaSponsor = ({ data: { articles } }) => {
  return (
    <Layout>
      <Seo title={`Diventa sponsor`} description={``} />

      <div className="box-intro box-intro--page-name">
        <Suspense>
          <Spline
            scene="https://prod.spline.design/LH1fpiv595HjQm6T/scene.splinecode"
            className="animation-circle"
          />
        </Suspense>
        <div className="box-intro__text">
          <div className="container-md">
            <div className="row">
              <div className="col-12">
                <div className="box-intro__page-name">
                  <span>Diventa</span> sponsor
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section section--grey-dark">
        <div id="diventa-sponsor" className="box-article">
          <div className="container-md">
            <div className="row position-relative">
              <div className="col-12 col-lg-10 col-xl-8 offset-xl-1">
                <h2 className="box-article__title">
                  {articles.nodes?.[0].articles?.[0].articleTitle}
                </h2>
                <div className="box-article__text">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        articles.nodes?.[0].articles?.[0].articleMarkdownText.childMarkdownRemark
                          ?.html,
                    }}
                  ></div>
                </div>
              </div>
              <Suspense>
                <Spline
                  scene="https://prod.spline.design/jYs8nIOMCpvxKeGi/scene.splinecode"
                  className="animation-text animation-text--dx"
                />
              </Suspense>
            </div>
          </div>
        </div>
      </section>

      <EngageBanner />

      <BannerTickets />

      <BoxIniziativa />

      <BoxSponsor />

      <BoxNewsletter />
    </Layout>
  );
};

export default DiventaSponsor;

export const query = graphql`
  query {
    articles: allContentfulArticlePage(filter: { entryTitle: { eq: "Diventa sponsor" } }) {
      nodes {
        articles {
          articleTitle
          articleMarkdownText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`;
